import React from 'react'
import logo from './logo.svg'
import { Counter } from './features/counter/Counter'
import './App.css'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { LoginArgs, useGetMeQuery, useLoginMutation, useLogoutMutation } from './app/api'

function App() {
  return (
    <div className="App">
      <h1>Testapp</h1>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Me />} />
      </Routes>
    </div>
  )
}

export default App

function Login() {
  const [login] = useLoginMutation()
  const navigate = useNavigate()

  const handleLogin = async () => {
    await login({email:"flo@lingu.at", password:"geheim33"}).unwrap()
    navigate("/")
  }

  return (
    <div>
      <h2>Login</h2>
      <button onClick={handleLogin}>Login</button>
    </div>
  )
}

function Me() {
  const { data, isError, isLoading, error } = useGetMeQuery()
  const [logout] = useLogoutMutation()

  const handleLogout = async () => {
    await logout().unwrap()
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <Navigate to="/login" replace />
  }

  return <div>Email: {data?.email} <button onClick={handleLogout}>Logout</button></div>
}
